// IE11 forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('prepend')) {
      return
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments)
        var docFrag = document.createDocumentFragment()

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)))
        })

        this.insertBefore(docFrag, this.firstChild)
      }
    })
  })
})([Element.prototype, Document.prototype, DocumentFragment.prototype])

window.addEventListener('load', () => {
  const cookieManager = document.getElementById('cookie-manager')
  const landing = document.getElementById('cookie-manager-landing')
  const form = document.getElementById('cookie-manager-form')
  const acceptAllBtns = document.querySelectorAll('.cookie-manager-btn-accept-all')
  const denyAllBtns = document.querySelectorAll('.cookie-manager-btn-deny-all')
  const showFormBtn = document.getElementById('cookie-manager-btn-show-form')
  const collapseElArray = document.querySelectorAll("*[data-toggle='cookie-collapse']")

  let formSubmitted = false

  form.addEventListener('submit', (e) => {
    e.preventDefault()

    const gaChecked = form.querySelector('#check-google-analytics').checked
    if (!gaChecked) {
      sendDisableAnalyticsEvent()
    } else {
      submitForm()
    }
  })

  const sendDisableAnalyticsEvent = () => {
    if (typeof window.gtag !== 'function') {
      submitForm()
    }

    // Submit the form after one second, so we continue even when the ga event fails
    setTimeout(submitForm, 1000)

    window.gtag('event', 'hit', {
      'event_category': 'Disable Analytics',
      'event_callback': submitForm
    })
  }

  const submitForm = () => {
    if (!formSubmitted) {
      formSubmitted = true
      form.submit()
    }
  }

  const openLanding = () => {
    if (form) form.style.display = 'none'
    if (landing) landing.style.display = ''
  }

  if (cookieManager) {
    cookieManager.style.display = ''
    openLanding()
  }

  const acceptAll = () => {
    Array.prototype.forEach.call(form.querySelectorAll('input[type="checkbox"]'), function (checkbox) {
      checkbox.checked = true
    })
    form.submit()
  }

  const denyAll = () => {
    Array.prototype.forEach.call(form.querySelectorAll('input[type="checkbox"]:not([disabled])'), function (checkbox) {
      checkbox.checked = false
    })
    form.submit()
  }

  const openForm = () => {
    if (form) form.style.display = ''
    if (landing) landing.style.display = 'none'
  }

  if (showFormBtn) {
    showFormBtn.addEventListener('click', openForm)
  }

  for (let btn of acceptAllBtns) {
    btn.addEventListener('click', acceptAll)
  }

  for (let btn of denyAllBtns) {
    btn.addEventListener('click', denyAll)
  }

  if (collapseElArray.length) {
    for (const collapseEl of collapseElArray) {
      collapseEl.addEventListener('click', function (event) {
        event.preventDefault()
        let targetId = this.getAttribute('data-target')
        let targetEl = document.getElementById(targetId)
        let contentEl = targetEl ? targetEl.children[0] : null
        if (contentEl) {
          if (targetEl.classList.contains('show')) {
            targetEl.style.height = contentEl.offsetHeight + 'px'
            targetEl.classList.remove('show')
            setTimeout(function () {
              targetEl.style = ''
            }, 100)
          } else {
            targetEl.style.height = '0px'
            targetEl.style.height = contentEl.offsetHeight + 'px'
            setTimeout(function () {
              targetEl.classList.add('show')
              targetEl.style = ''
            }, 400)
          }
          let collapseButtons = document.querySelectorAll("*[data-target='" + targetId + "']")
          for (let collapseButton of collapseButtons) {
            collapseButton.classList.toggle('d-none')
          }
        }
      })
    }
  }
})
